import React from "react"
import Layout from "../components/layout"
import PageSlider from "../components/slider"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import Collection from "../components/collection"

export const query = graphql`
  query {
    agileCycle: file(relativePath: { eq: "agile-cycle.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rimLogo: file(relativePath: { eq: "rim-logo.png" }) {
      childImageSharp {
        fluid(maxHeight: 71) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    exactLogo: file(relativePath: { eq: "exact-logo.png" }) {
      childImageSharp {
        fluid(maxHeight: 71) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    festoLogo: file(relativePath: { eq: "festo-logo.png" }) {
      childImageSharp {
        fluid(maxHeight: 71) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ibmLogo: file(relativePath: { eq: "ibm-logo.png" }) {
      childImageSharp {
        fluid(maxHeight: 71) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    advisieLogo: file(relativePath: { eq: "advisie-logo.png" }) {
      childImageSharp {
        fluid(maxHeight: 71) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    solutions: allMarkdownRemark(filter: {fields: { collection: { eq: "solutions" }}}) {
      edges {
        node {
          id
          fields {
              slug
          }
          frontmatter{
            title,
            subtitle,
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 530, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    certifications: allMarkdownRemark(filter: {fields: { collection: { eq: "certifications" }}}) {
      edges {
        node {
          id
          fields {
              slug
          }
          frontmatter{
            title,
            subtitle,
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 530, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = () => {
  const result = useStaticQuery(query);
  //console.log(result);
  const solutions = result.solutions.edges.map(({node: page}) => {
    return {
      key: page.fields.slug,
      title: page.frontmatter.title,
      subtitle: page.frontmatter.subtitle,
      url: page.fields.slug, 
      featuredImage: page.frontmatter.featuredImage
  }});
  var customers = [
    {key: 1, url:"/rim-projects", featuredImage: result.rimLogo},
    {key: 2, url:"/exact-projects", featuredImage: result.exactLogo},
    {key: 3, url:"/festo-projects", featuredImage: result.festoLogo },
    {key: 4, url:"/ibm-projects", featuredImage: result.ibmLogo },
    {key: 5, url:"/advisie-projects", featuredImage: result.advisieLogo },
  ];

  const certifications = result.certifications.edges.map(({node: page}) => {
    return {
      key: page.fields.slug,
      title: page.frontmatter.title,
      subtitle: page.frontmatter.subtitle,
      url: page.fields.slug, 
      featuredImage: page.frontmatter.featuredImage
  }});

  return (
  <Layout>
    <SEO title="DEVSPARK" />
    <PageSlider />
    <div className="container">
      <Collection sectionTitle="Expertise" items={solutions} hideSubtitle="false" mobileColumnsCount="2"/>
      <div className="section">
        <div className="columns is-vcentered">
          <div className="column is-half-desktop">
            <div className="content has-text-right">
              <h1>Our Approach</h1>
              <p>DEVSPARK nurtures our technology competencies to enhance your business and help you 
                achieve your goals, answering real business challenge through innovation and deep industry 
                knowledge.
              </p>
              <p>The company consistently goes beyond its clients’ expectations by bringing 
                together technology, talent, innovation, and the highest quality standards.
              </p>
            </div>
          </div>
          <div className="column is-half-desktop">
              <Img className="is-max-343x343" fluid={result.agileCycle.childImageSharp.fluid}></Img>
          </div>
        </div>
      </div>
      <Collection sectionTitle="CUSTOMERS" items={customers}/>
      <Collection sectionTitle="CERTIFIED PROFESSIONALS" items={certifications} mobileColumnsCount="2"/>
    </div>
  </Layout>
)}

export default IndexPage
