import React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "gatsby-image";
import Slider from "react-slick";
import { StaticQuery, graphql } from "gatsby"

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  adaptiveHeight: false,
  variableWidth: false,
  arrows: false,
  mobileFirst: true,
  dotsClass: 'slick-dots-override'
};

export const sliderImage = graphql`
  fragment sliderImage on File {
    childImageSharp {
      fluid(maxHeight: 475, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export const query = graphql`
  query {
    slide1: file(relativePath: { eq: "slide1.jpg" }) {
      ...sliderImage
    }
    slide2: file(relativePath: { eq: "slide2.jpg" }) {
      ...sliderImage
    }
    slide3: file(relativePath: { eq: "slide3.jpg" }) {
      ...sliderImage
    }
    slide4: file(relativePath: { eq: "slide4.jpg" }) {
        ...sliderImage
      }
  }
`
const slidesText = {
  1: {
    title: "Software developer teams working remotely",
    subtitle: "Our team strategy the exact solution for your needs, making business more intelligent and profitable."
  },
  2: {
    title: " Provider of high-end business solutions",
    subtitle: "Answering real business challenges through innovation and deep industry knowledge."
  },
  3: {
    title: "Enhance business and help you achieve your goals",
    subtitle: "Strategic IT business solutions enabling business success."
  }, 
  4: {
    title: "Large network of innovative software developers",
    subtitle: "The experience allows us to work across a broad range of platforms and programming languages."
  }
}

const PageSlider = () => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <Slider {...settings}>
          {[1, 2, 3, 4].map(i => (
            <div key={`$slide-{i}`} className="is-relative">
              <Img key={`$slide-{i}-img`} className="slide-height-desktop" fluid={data[`slide${i}`].childImageSharp.fluid}></Img>
              <div key={`$slide-{i}-txt`} className="slide-text has-text-white has-text-centered">
                <div key={`$slide-{i}-ttl`} className="is-size-1-desktop is-size-4-mobile has-text-weight-bold">{slidesText[i].title}</div>
                <div key={`$slide-{i}-subttl`} className="is-size-5-desktop is-size-6-mobile">{slidesText[i].subtitle}</div>
              </div>
            </div>
          )
          )}
        </Slider>
      )}
    />
  )
}

export default PageSlider
